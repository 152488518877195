<template>
  <ChatMessage
    :createdBy="createdBy"
    :me="isMe"
    :message="message">
  </ChatMessage>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      participants: 'report/invite/getMembers'
    }),

    isMe () {
      const userId = this.$store.getters['auth/getUserId']
      return this.message.createdBy === userId
    },

    createdBy () {
      const participants = this.$store.getters['report/invite/getMembers'] || []
      const i = participants.findIndex(participant => participant.id === this.message.createdBy)
      return i !== -1 ? participants[i].getFullName() : this.$i18n.t('hunt.unknownParticipant')
    }
  }
}
</script>
