<template>
  <ChatForm
    :loading="submitting"
    :placeholder="$t('hunt.sendMessageToParticipantsPlaceholder')"
    @send="onSendMessage">
  </ChatForm>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      submitting: false
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'report/getHunt'
    })
  },

  methods: {
    async onSendMessage (message) {
      if (!message.length) return

      this.submitting = true

      try {
        await this.$store.dispatch('report/chat/sendMessage', {
          huntId: this.hunt.id,
          text: message
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      }

      this.submitting = false
    }
  }
}
</script>
