<template>
  <Chat class="chat-tab">
    <ChatMessagesPlaceholder v-if="isLoading">
      <ChatMessagePlaceholder
        v-for="i in 10"
        :key="i"
        :me="i % 3 === 0">
      </ChatMessagePlaceholder>
    </ChatMessagesPlaceholder>

    <ChatMessages v-else>
      <ReportPageTabChatBubble
        v-for="(message, i) in messages"
        :key="`${message.id}-${i}`"
        :message="message">
      </ReportPageTabChatBubble>
    </ChatMessages>

    <ReportPageTabChatForm />
  </Chat>
</template>

<script>
import ReportPageTabChatBubble from './ReportPageTabChatBubble.vue'
import ReportPageTabChatForm from './ReportPageTabChatForm.vue'

export default {
  components: {
    ReportPageTabChatBubble,
    ReportPageTabChatForm
  },

  computed: {
    messages () {
      const messages = this.$store.getters['report/chat/getMessages'] || []
      return messages.slice().sort((a, b) => a.createdAt - b.createdAt)
    },

    isLoading () {
      return this.$store.getters['report/chat/getMessages'] === null
    }
  }
}
</script>

<style scoped>
.chat-tab {
  padding: 0 !important;
}
</style>
